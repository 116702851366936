import React, { useState, useEffect } from "react";
import forms_pl from "../../data/forms_pl.json";
import forms_en from "../../data/forms_en.json";
import { getNewsBpower } from "../api/api";
import _ from "lodash";

export const DocasContent = React.createContext();

const DocasContentStore = props => {
    const [language, setLanguage] = useState();
    const [homepage, setHomepage] = useState({});
    const [aboutUs, setAboutUs] = useState({});
    const [news, setNews] = useState({});
    const [contact, setContact] = useState({});
    const [footer, setFooter] = useState({});
    const [navbar, setNavbar] = useState({});
    const [help, setHelp] = useState({});
    const [questions, setQuestions] = useState({});
    const [latestForms, setLatestForms] = useState({});
    const [formsFilters, setFormsFilters] = useState({});
    const [forms, setForms] = useState([]);
    const [posts, setPosts] = useState([]);
    const [homepageNews, setHomepageNews] = useState([]);
    const [register, setRegister] = useState([]);
    const [newsPage, setNewsPage] = useState([]);

    useEffect(() => {
        setForms(language === "English" ? forms_en : forms_pl);

        const posts = getNewsBpower(language);
        posts.then(res => {
            const arr = [];
            _.forIn(res, function(value, key) {
                arr.push(res[key]);
            });

            setPosts(arr.reverse());
        });
    }, [language]);

    return (
        <DocasContent.Provider
            value={{
                language,
                setLanguage,
                homepage,
                setHomepage,
                aboutUs,
                setAboutUs,
                news,
                setNews,
                contact,
                setContact,
                footer,
                setFooter,
                navbar,
                setNavbar,
                help,
                setHelp,
                questions,
                setQuestions,
                forms,
                latestForms,
                setLatestForms,
                formsFilters,
                setFormsFilters,
                posts,
                homepageNews,
                setHomepageNews,
                register,
                setRegister,
                newsPage,
                setNewsPage
            }}
        >
            {props.children}
        </DocasContent.Provider>
    );
};

export default DocasContentStore;
