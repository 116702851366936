import React, { Component } from "react";
import { FacebookProvider, Comments, Like } from "react-facebook";
// import firebase from "firebase";
// import { newsEnvironment } from "../firebase/config";
import TwitterShareLink from "react-twitter-share-link";
import { getSingleNewsBpower } from "../api/api";
import { DocasContent } from "../context/context";
import parser from "html-react-parser";
import cookies from "js-cookie";

class Article extends Component {
    static contextType = DocasContent;
    state = {
        news: {},
        lang: ""
    };

    componentWillMount() {
        const article = getSingleNewsBpower(
            this.props.match.params.id,
            cookies.get("docasLanguage")
        );
        article.then(res => {
            this.setState({ news: res });
        });
    }
    goBack = () => {
        window.history.back();
    };
    render() {
        const content = this.context.newsPage;
        return (
            <div className="article">
                <h2 className="text-center article-title">
                    {this.state.news.title}
                </h2>
                <div className="article-image float-left">
                    {this.state.news.img ? parser(this.state.news.img) : null}
                </div>
                <div className="article-text">
                    <p>{this.state.news.text}</p>
                </div>
                <br />
                <div className="socials">
                    <FacebookProvider appId="334768987246948">
                        <Comments width={"100%"} href={window.location.href} />
                    </FacebookProvider>
                    <div className="social-container">
                        <FacebookProvider appId="334768987246948">
                            <Like
                                size={"large"}
                                width={"200"}
                                href={window.location.href}
                                colorScheme="dark"
                                showFaces
                                share
                            />
                        </FacebookProvider>
                        <TwitterShareLink link={window.location.href}>
                            {link => (
                                <a
                                    className="tweet-button margin-inline-start"
                                    href={link}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <i className="fab fa-twitter" />
                                    Tweet
                                </a>
                            )}
                        </TwitterShareLink>
                    </div>
                </div>
                <button
                    className="btn docas-btn-primary d-block margin-inline-start"
                    onClick={this.goBack}
                >
                    {content.text_6}
                </button>
            </div>
        );
    }
}

export default Article;
