import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { getContentBpower } from "../api/api";
import { DocasContent } from "../context/context";
import { useCookies } from "react-cookie";

import pl from "../../images/flags/pl.png";
import uk from "../../images/flags/uk.png";
// import LoadingBanner from "../Loading/LoadingBanner";

const Language = props => {
    const context = useContext(DocasContent);
    // const [isLoading, setIsLoading] = useState(true);
    const [cookies, setCookie] = useCookies();

    // console.log(context);

    useEffect(() => {
        const locations = [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 15];
        // 1 - navbar
        // 2 - footer
        // 3 - homepage
        // 4 - contact
        // 5 - help
        // 6 - help questions
        // 7 - latest forms
        // 8 - forms
        // 10 - homepage news
        // 11 - register
        // 15 - news

        locations.map(location => {
            const getContent = getContentBpower(location, context.language);
            getContent.then(res => {
                switch (location) {
                    case 1:
                        context.setNavbar(res);
                        break;
                    case 2:
                        context.setFooter(res);
                        break;
                    case 3:
                        context.setHomepage(res);
                        break;
                    case 4:
                        context.setContact(res);
                        break;
                    case 5:
                        context.setHelp(res);
                        break;
                    case 6:
                        context.setQuestions(res);
                        break;
                    case 7:
                        context.setLatestForms(res);
                        break;
                    case 8:
                        context.setFormsFilters(res);
                        break;
                    case 10:
                        context.setHomepageNews(res);
                        break;
                    case 11:
                        context.setRegister(res);
                        break;
                    case 15:
                        context.setNewsPage(res);
                        break;
                    // case 12:
                    //     context.setHomepage(res);
                    //     break;
                    // case 13:
                    //     context.setAboutUs(res);
                    //     break;
                    // case 14:
                    //     context.setNews(res);
                    //     break;
                    // case 15:
                    //     context.setContact(res);
                    //     break;
                    // case 16:
                    //     context.setFooter(res);
                    //     break;
                    // case 17:
                    //     context.setNavbar(res);
                    //     break;
                    default:
                        return null;
                }
            });
            return null;
        });
        if (cookies.docasLanguage) {
            // dispatch({ type: "SET_LANGUAGE", payload: cookies.docasLanguage });
            context.setLanguage(cookies.docasLanguage);
        } else {
            // dispatch({ type: "SET_LANGUAGE", payload: "English" });
            setCookie("docasLanguage", "Polish");
            context.setLanguage("Polish");
        }

        //eslint-disable-next-line
    }, [context.language]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 1000);
    // }, [context.state.homepage]);

    const changeLanguage = lang => {
        setCookie("docasLanguage", lang);
        // setIsLoading(true);
        window.location.reload();
    };

    return (
        <>
            <Div className="languages">
                <Img src={uk} onClick={() => changeLanguage("English")} />
                <Img src={pl} onClick={() => changeLanguage("Polish")} />
            </Div>
            {/* {isLoading ? <LoadingBanner /> : null} */}
        </>
    );
};

export default Language;

const Div = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    z-index: 1050;
`;

const Img = styled.img`
    width: 20px;
    height: 20px;
    margin: 5px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.1);
    }
`;
