import React from "react";
import "../../styles/homepage.css";
import FindForm from "../homepage/FindForm";
import LastForms from "../homepage/LastForms";
import ExploreDocas from "../homepage/ExploreDocas";
import Statistics from "../homepage/Statistics";
import News from "../homepage/News";
import Reviews from "../homepage/Reviews";
import { DocasContent } from "../context/context";

class HomePage extends React.Component {
    static contextType = DocasContent;
    state = {
        isLoading: true,
        forms: []
    };

    componentDidUpdate(prevProps) {
        if (this.state.forms !== this.context.forms) {
            this.setState({ forms: this.context.forms });
        }
    }

    render() {
        const content = this.context.homepage;
        const all = this.context.latestForms.text_10;
        return (
            <div className="home">
                {this.context.forms ? (
                    <>
                        <FindForm
                            forms={this.context.forms}
                            content={content}
                            all={all}
                        />
                        <LastForms
                            forms={this.context.forms}
                            content={content}
                            all={all}
                        />
                    </>
                ) : null}

                <ExploreDocas content={content} />
                <Statistics content={content} />
                <News content={content} />
                <Reviews content={content} />
            </div>
        );
    }
}

export default HomePage;
