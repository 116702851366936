import React, { Component } from "react";
import line_left from "../../images/01_Home_bg_line_3.png";

class Statistics extends Component {
    render() {
        const content = this.props.content;
        return (
            <div className="container-fluid statistics">
                <div className="row">
                    <img alt="lines" src={line_left} />
                </div>
                <div className="container">
                    <div className="row statistics-row">
                        <div className="statistics-value">
                            <h5>{content.text_9}</h5>
                            <p className="selected">
                                <span>{content.text_10}</span>
                                {content.text_11}
                            </p>
                        </div>
                        <div className="statistics-value">
                            <h5>{content.text_12}</h5>
                            <span className="selected">{content.text_13}</span>
                        </div>
                        <div className="statistics-value">
                            <h5>{content.text_14}</h5>
                            <span className="selected">{content.text_15}</span>
                        </div>
                        <div className="statistics-value">
                            <h5>{content.text_16}</h5>
                            <p className="selected">
                                <span>{content.text_17}</span>
                                {content.text_18}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Statistics;
