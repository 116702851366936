import React from "react";
import logo from "../images/docas_logo.png";
import { Link, NavLink } from "react-router-dom";
import "../styles/footer.css";
import euroDotation from "../images/logo_dof.jpg";
import { DocasContent } from "./context/context";

class Footer extends React.Component {
    static contextType = DocasContent;
    render() {
        const content = this.context.footer;

        return (
            <div className="container-fluid">
                <footer>
                    <div className="row">
                        <div className="col-sm-3 d-flex flex-column align-items-center">
                            <div className="footer-logo">
                                <Link to="/">
                                    <img
                                        alt="logo-footer"
                                        className="logo-footer"
                                        src={logo}
                                    />
                                </Link>
                                <p>
                                    {content.text_1}{" "}
                                    <span className="text-primary">
                                        {content.text_2}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-3 d-flex flex-column align-items-center">
                            <div className="d-flex flex-column">
                                <p className="font-weight-bold text-center">
                                    {content.text_3}
                                </p>
                                <ul className="text-center">
                                    <li className="p-footer font-weight-bold">
                                        {content.text_16}
                                    </li>
                                    <li className="p-footer">
                                        {content.text_17}
                                    </li>
                                    <li className="p-footer">
                                        {content.text_18}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3 d-flex flex-column align-items-center">
                            <div className="d-flex flex-column">
                                <p className="font-weight-bold text-center">
                                    {content.text_4}
                                </p>
                                <div className="row justify-content-center shortcut-menu">
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/help"
                                        >
                                            {content.text_7}
                                        </NavLink>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/contact"
                                        >
                                            {content.text_8}
                                        </NavLink>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/forms"
                                        >
                                            {content.text_9}
                                        </NavLink>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/regulations"
                                        >
                                            {content.text_10}
                                        </NavLink>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/payments"
                                        >
                                            {content.text_11}
                                        </NavLink>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/news"
                                        >
                                            {content.text_12}
                                        </NavLink>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <a
                                            className=""
                                            href="http://app.docas24.com/index.php/site/login"
                                        >
                                            {content.text_13}
                                        </a>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/register"
                                        >
                                            {content.text_14}
                                        </NavLink>
                                    </span>
                                    <span className="p-footer mx-2">
                                        <NavLink
                                            activeClassName="selected"
                                            to="/privacy-policy"
                                        >
                                            {content.text_15}
                                        </NavLink>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 d-flex flex-column align-items-center font-weight-bold">
                            <p>{content.text_5}</p>
                            <NavLink
                                to="/contact"
                                className="btn btn-primary report"
                            >
                                {content.text_6}
                            </NavLink>
                        </div>
                    </div>
                    <div className="container text-center">
                        <img
                            className="dotation-image w-100"
                            alt="dotation"
                            src={euroDotation}
                        />
                        <p className="p-footer h-100">{content.text_19}</p>
                    </div>
                    <div className="footer-social-media container">
                        <div className="row">
                            <div className="col-4 d-flex justify-content-center">
                                <a
                                    href="https://www.facebook.com/docas24/"
                                    className="facebook"
                                >
                                    <i className="fab fa-facebook-f" />
                                </a>
                            </div>
                            <div className="col-4 d-flex justify-content-center">
                                <a
                                    href="https://twitter.com/docas24"
                                    className="twitter"
                                >
                                    <i className="fab fa-twitter" />
                                </a>
                            </div>
                            <div className="col-4 d-flex justify-content-center">
                                <a
                                    href="https://www.linkedin.com/company/docas24/"
                                    className="LinkedIn"
                                >
                                    <i className="fab fa-linkedin-in" />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
