import React from "react";

const Map = () => {
  return (
    <iframe
      title="map"
      src="https://maps.google.com/maps?q=%C5%9Bwi%C4%99tos%C5%82awa%20174&t=&z=15&ie=UTF8&iwloc=&output=embed"
    ></iframe>
  );
};

export default Map;
