import React, { Component } from "react";
import { Link } from "react-router-dom";
import parser from "html-react-parser";
import { DocasContent } from "../context/context";

class News extends Component {
    static contextType = DocasContent;

    state = {
        news: [],
        lang: ""
    };
    onChangeText = html => {
        const tmp = document.createElement("div");
        tmp.innerHTML = html;
        return tmp.textContent.slice(0, 200) || tmp.innerText.slice(0, 200);
    };

    componentDidUpdate(preProps) {
        if (this.state.lang !== this.context.language) {
            this.setState({ lang: this.context.language });
        }
    }

    render() {
        const content = this.props.content;
        return (
            <div className="container-fluid news">
                <h2>{content.text_19}</h2>
                <div className="row">
                    {this.context.posts.map((article, index) => {
                        if (index < 3) {
                            return (
                                <div className="news-content" key={article.id}>
                                    <div className="news-square d-flex justify-content-center align-items-center">
                                        {parser(article.image)}
                                    </div>
                                    <div className="news-text">
                                        <h3 className="px-3">
                                            {article.title}
                                        </h3>
                                        <p className="text-justify px-3">
                                            {this.onChangeText(article.text)}
                                            ...
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Link
                                            to={`/news/${article.id}`}
                                            className="btn btn-outline-primary read-more-article"
                                        >
                                            {this.context.homepageNews.text_1}
                                        </Link>
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
                <div className="docas-show-more col-12">
                    <Link
                        to="/news"
                        className="d-inline-block btn btn-primary docas-show-more-button"
                    >
                        {this.context.homepageNews.text_2}
                    </Link>
                </div>
            </div>
        );
    }
}

export default News;
