import React, { Component } from "react";
import RadioButton from "./RadioButton";
// import ReCaptcha from 'react-recaptcha';

class Form extends Component {
    state = {
        rules: false,
        copy: false,
        name: "",
        email: "",
        phone: "",
        text: ""
    };
    changeForm = e => {
        const inputState = {};
        inputState[e.target.name] = e.target.value;
        this.setState(inputState);
    };

    rulesChange = () => {
        var currentState = this.state.rules;
        this.setState({ rules: !currentState });
    };

    copyChange = () => {
        var currentState = this.state.copy;
        this.setState({ copy: !currentState });
    };

    sendForm = () => {
        var rules = document.querySelector(".form-checkbox-btn p");
        if (!this.state.rules) {
            rules.classList.add("text-danger");
        } else {
            rules.classList.remove("text-danger");
        }
    };

    render() {
        const content = this.props.content;
        return (
            <form
                action="https://formspree.io/&#102;&#114;&#111;&#110;&#116;&#101;&#110;&#100;&#046;&#098;&#112;&#111;&#119;&#101;&#114;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;"
                method="POST"
            >
                <input
                    onChange={this.changeForm}
                    className="col-12 contact-field"
                    type="text"
                    name="name"
                    placeholder={content.text_2}
                    required
                />
                <input
                    onChange={this.changeForm}
                    className="col-6 contact-field"
                    type="email"
                    name="email"
                    placeholder={content.text_3}
                    required
                />
                <input
                    onChange={this.changeForm}
                    className="col-6 contact-field"
                    type="text"
                    pattern="\d+"
                    name="phone"
                    placeholder={content.text_4}
                />
                <textarea
                    onChange={this.changeForm}
                    className="col-12 contact-field"
                    name="text"
                    placeholder={content.text_5}
                    required
                />
                <div className="form-checkbox-btns">
                    <RadioButton
                        name="rules"
                        action={this.rulesChange}
                        text={content.text_6}
                    />
                    <RadioButton
                        name="copy"
                        action={this.copyChange}
                        text={content.text_7}
                    />
                    <input
                        type="hidden"
                        name="_cc"
                        value={this.state.copy ? this.state.email : ""}
                    />
                    <input type="hidden" name="_language" value="pl" />
                    <input
                        type="hidden"
                        name="_next"
                        value="https://docas24.com/success"
                    />
                </div>
                <input
                    onClick={this.sendForm}
                    type={this.state.rules ? "submit" : "button"}
                    value={content.text_8}
                    className="btn docas-btn-primary float-right"
                />
            </form>
        );
    }
}

export default Form;
