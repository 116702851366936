import React, { useContext, useState, useEffect } from "react";
import ListForm from "./ListForms";
import { DocasContent } from "../context/context";

const LastForms = props => {
    const context = useContext(DocasContent);
    const [state, setState] = useState({
        activeItem: "",
        forms: [],
        items: [],
        formCount: 6,
        filteredForms: []
    });

    // state = {

    // };

    const findForm = item => {
        if (item === state.items[0]) {
            setState({
                ...state,
                forms: context.forms,
                activeItem: item,
                formCount: 6
            });
        } else {
            setState({
                ...state,
                activeItem: item,
                forms: context.forms.filter(form => {
                    return form.type === item;
                })
            });
        }
    };

    useEffect(() => {
        let items = context.forms.map(function(item) {
            return item.type;
        });
        items.unshift(props.all);
        items = [...new Set(items)];
        setState({ ...state, items: items, forms: context.forms });
        // eslint-disable-next-line
    }, [context.forms, props.all]);

    const content = props.content;

    return (
        <div className="container-fluid last-form">
            <div className="block-last-form">
                <h2 className="text-center">{content.text_4}</h2>
                <div className="form-btns-filters">
                    {state.items.map(item => {
                        return (
                            <button
                                className={
                                    state.activeItem === item
                                        ? "btn btn-primary"
                                        : "btn btn-link"
                                }
                                key={item}
                                onClick={() => findForm(item)}
                            >
                                {item}
                            </button>
                        );
                    })}
                </div>
                {state.forms ? (
                    <ListForm formCount={state.formCount} forms={state.forms} />
                ) : null}
            </div>
        </div>
    );
};

export default LastForms;
