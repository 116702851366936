import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import logo from "../images/docas_logo.png";
import logoUE from "../images/UE_header_n.jpg";
import "../styles/header.css";
import "../styles/hamburger.css";
import { DocasContent } from "./context/context";
class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: ""
        };
    }

    static contextType = DocasContent;

    activeClass = () => {
        if (this.state.active) {
            this.setState({ active: "" });
        } else {
            this.setState({ active: "is-active" });
        }
    };
    disableMenu = () => {
        this.setState({ active: "" });
        const btn = document.querySelector(".hamburger--slider");
        const nav = document.querySelector(".navbar-collapse");
        btn.setAttribute("aria-expanded", "false");
        nav.classList.remove("show");
    };
    render() {
        const content = this.context.navbar;

        return (
            <header className="header container-fluid d-flex flex-column">
                <div className="d-flex justify-content-between mt-5">
                    <Link className="navbar-brand offset-xl-1" to="/">
                        <img alt="logo" className="logo" src={logo} />
                    </Link>
                    <Link to="/contact#company-info">
                        <img
                            alt="logo"
                            className="logoUE"
                            style={{ width: "88px", marginRight: "8%" }}
                            src={logoUE}
                        />
                    </Link>
                    
                </div>
                <nav className="navbar navbar navbar-light bg-light navbar-expand-lg d-flex justify-content-end">
                    <button
                        className={
                            "navbar-toggler hamburger hamburger--slider " +
                            this.state.active
                        }
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                        onClick={() => this.activeClass()}
                    >
                        <span className="hamburger-box">
                            <span className="hamburger-inner" />
                        </span>
                    </button>

                    <div
                        className="collapse navbar-collapse"
                        id="navbarCollapse"
                    >
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    to="/help"
                                    onClick={this.disableMenu}
                                >
                                    {content.text_1}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    to="/contact"
                                    onClick={this.disableMenu}
                                >
                                    {content.text_2}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    to="/forms"
                                    onClick={this.disableMenu}
                                >
                                    {content.text_3}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    to="/news"
                                    onClick={this.disableMenu}
                                >
                                    {content.text_4}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    to="/regulations"
                                    onClick={this.disableMenu}
                                >
                                    {content.text_5}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    to="/register"
                                    onClick={this.disableMenu}
                                >
                                    {content.text_6}
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex align-items-center">
                                <a
                                    className="btn btn-outline-primary log-in"
                                    href="http://app.docas24.com/index.php/site/login"
                                >
                                    {content.text_7}
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;
