import React from "react";
import home_bg from "../../images/01_Home_bg.png";
import { Link } from "react-router-dom";

class FindForm extends React.Component {
    state = {
        search: ""
    };
    onChange = e => {
        this.setState({ search: e.target.value });
    };
    render() {
        const content = this.props.content;
        return (
            <div className="container-fluid find-form">
                <div className="row">
                    <img alt="home" className="find-form-image" src={home_bg} />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 block-find-form">
                            <h1>{content.text_1}</h1>
                            <div className="input-group mb-3">
                                <input
                                    value={this.state.search}
                                    onChange={this.onChange}
                                    type="text"
                                    className="form-control docas-input"
                                    placeholder={content.text_2}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <i className="fas fa-search fa-2x" />
                            </div>
                            <Link
                                to={{
                                    pathname: "/forms",
                                    state: this.state.search
                                }}
                                className="btn btn-primary docas-find-form-button"
                            >
                                {content.text_3}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FindForm;
