import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import computer_bg from "../../images/01_Home_computer_with_bg.png";
import line_right from "../../images/01_Home_bg_line_2.png";

class ExploreDocas extends Component {
    render() {
        const content = this.props.content;
        return (
            <div className="container-fluid explore-docas">
                <div className="row">
                    <img alt="lines" src={line_right} />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="macbook col-xl-6">
                            <img alt="computer" src={computer_bg} />
                        </div>
                        <div className="col-xl-6">
                            <div className="explore-docas-text">
                                <h2>{content.text_5}</h2>
                                <p>{content.text_6}</p>
                                <div className="explore-docas-btns">
                                    <a
                                        href="http://app.docas24.com/index.php/site/login"
                                        className="btn docas-btn-primary"
                                    >
                                        {content.text_7}
                                    </a>
                                    <NavLink
                                        to="/register"
                                        className="btn docas-btn-outline-primary"
                                    >
                                        {content.text_8}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExploreDocas;
