import React, { Component } from "react";
import Question from "./Question";
import lines_left from "../../images/01_Home_bg_line_3.png";
import _ from "lodash";

class Questions extends Component {
    state = {
        questions: this.props.questions
    };
    render() {
        // console.log(this.props.q);
        let i = 0;
        let x = 0;
        let arr = [];
        _.forIn(this.props.q, function(value, key) {
            if (i % 2 === 0) {
                arr.push({ title: value });
            } else {
                arr[x].text = value;
                x++;
            }

            i++;
        });

        return (
            <div className="container-fluid questions">
                <div className="row">
                    <img src={lines_left} alt="lines" />
                </div>
                <div className="container">
                    <h2 className="text-center">{this.props.content.text_3}</h2>
                    {arr.map((question, index) => {
                        return (
                            <section className="question" key={index}>
                                <Question
                                    id={index + 1}
                                    title={question.title}
                                    text={question.text}
                                />
                            </section>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Questions;
