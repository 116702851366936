import React from "react";
import formImg from "../../images/file.png";
import "../../styles/modal.css";

const Modal = ({
    handleClose,
    activeModal,
    formName,
    formCategory,
    formType,
    formDescription,
    formTag,
    formImage,
    formLink,
    children,
    content
}) => {
    const showHideClassName = activeModal
        ? "modal fade bd-example-modal-lg show"
        : "modal fade bd-example-modal-lg";

    return (
        <div
            className={showHideClassName}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {formName}
                        </h5>
                        <button
                            onClick={handleClose}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <img
                                className="modal-image"
                                alt="document"
                                src={
                                    formImage === ""
                                        ? formImg
                                        : require(`../../images/forms_images/${formImage}`)
                                }
                            />
                            <ul className="modal-list">
                                <li>
                                    <p>
                                        <span className="font-weight-bold">
                                            {content.text_4}
                                        </span>{" "}
                                        {formCategory}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span className="font-weight-bold">
                                            {content.text_5}
                                        </span>{" "}
                                        {formType}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span className="font-weight-bold">
                                            {content.text_6}
                                        </span>{" "}
                                        {formDescription}
                                    </p>
                                </li>
                                <li className="d-flex">
                                    <p>
                                        <span className="font-weight-bold">
                                            {content.text_7}
                                        </span>
                                    </p>
                                    <ul className="d-flex moda-tag-list">
                                        {formTag.map((tag, index) => {
                                            return (
                                                <li key={index}>
                                                    <p>#{tag}</p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a
                            className="btn btn-primary"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={formLink}
                        >
                            {content.text_8}
                        </a>
                        <button
                            onClick={handleClose}
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            {content.text_9}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
