import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import parser from "html-react-parser";

const Payments = () => {
    const [state, setState] = useState("");
    const [cookies] = useCookies();

    useEffect(() => {
        if (cookies.docasLanguage === "English") {
            axios
                .get(
                    `https://app.docas24.com/index.php/restApi/common-posts/params/{"link_id":13}`
                )
                .then(res => setState(res.data[0].post.post_content));
        } else {
            axios
                .get(
                    `https://app.docas24.com/index.php/restApi/common-posts/params/{"link_id":13}`
                )
                .then(res => {
                    res.data[0].translations.map(translate =>
                        setState(translate.translation)
                    );
                });
        }
        // eslint-disable-next-line
    }, []);
    return <>{parser(state)}</>;
};

export default Payments;
