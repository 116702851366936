import React from "react";
import help_bg from "../../images/03_Help_bg.png";

const HelpSupport = props => {
    const content = props.content;

    return (
        <div className="container-fluid help">
            <div className="row">
                <img src={help_bg} alt="help" />
            </div>
            <div className="container p-0">
                <div className="row">
                    <div className="col-lg-7 help-and-support">
                        <h2>{content.text_1}</h2>
                        <p>{content.text_2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpSupport;
