import React from "react";
import lines from "../../images/01_Home_bg_line_2.png";
import contactImg from "../../images/04_Contact_bg.png";
import "../../styles/contact.css";
import Form from "../contact/Form";
import Map from "../contact/Map";
import { DocasContent } from "../context/context";
import parse from "html-react-parser";

class ContactPage extends React.Component {
  static contextType = DocasContent;

  state = {
    check: "fas fa-check-circle",
  };
  onChecked = (e) => {
    //eslint-disable-next-line
    var elemClass = e.target.classList;
  };
  render() {
    const content = this.context.contact;
    return (
      <React.Fragment>
        <div className="container-fluid contact-details">
          <div className="row">
            <img src={lines} className="col-12" alt="lines" />
          </div>
          <div className="container p-0 contact-details-info">
            <div className="row">
              <h3 className="col-12 text-center">{content.text_1}</h3>
              <div className="col-lg-6">
                <Map />
              </div>
              <div className="col-lg-6">
                <Form content={content} />
              </div>
            </div>
          </div>
        </div>
        <h3 className="col-12 text-center">{content.text_9}</h3>
        <div className="container contact-details-blocks">
          <div className="address">
            <h5>{content.text_10}</h5>
            <p>MP NEW sp. z o.o. w LEŻAJSKU</p>
          </div>
          <div className="email">
            <h5>{content.text_11}</h5>
            <p>ul. Świętosława 174</p>
            <p>39-200 Dębica</p>
          </div>
          <div className="phone-number">
            <h5>{content.text_12}</h5>
            <p>NIP 8161705454</p>
            <p>KRS 0000682921</p>
            <p>REGON 367543704</p>
          </div>
        </div>
        <div id="company-info" className="container company-info">
          <div className="row">
            <div className="col-6 contact-image-column">
              <img alt="contact" src={contactImg} />
            </div>
            <div className="col-6 contact-info-column">
              {content.text_13 ? parse(content.text_13) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactPage;
