import React, { Component } from "react";
import TinySlider from "tiny-slider-react";
import cookies from "js-cookie";
import reviews_pl from "../../data/reviews.json";
import reviews_en from "../../data/reviews_en.json";

class Reviews extends Component {
    state = {
        reviews: ""
    };

    onGoTo = dir => this.ts.slider.goTo(dir);

    componentWillMount() {
        if (cookies.get("docasLanguage") === "English") {
            this.setState({ reviews: reviews_en });
        } else {
            this.setState({ reviews: reviews_pl });
        }
    }

    render() {
        const settings = {
            lazyload: true,
            nav: false,
            mouseDrag: true,
            gutter: 0,
            controls: false,
            responsive: {
                420: {
                    items: 3
                }
            }
        };

        const content = this.props.content;

        return (
            <div className="container-fluid block-reviews">
                <h2 className="text-center">{content.text_20}</h2>
                <TinySlider settings={settings} ref={ts => (this.ts = ts)}>
                    {this.state.reviews.map(review => {
                        return (
                            <div key={review.id} className="review-text-box">
                                <div className="review-text">
                                    <h3>{review.auth}</h3>
                                    <h5 className="selected">{review.spec}</h5>
                                    <h4>{review.text}</h4>
                                </div>
                            </div>
                        );
                    })}
                </TinySlider>
                <div className="reviews-btn-block text-center">
                    <button
                        onClick={() => this.onGoTo("prev")}
                        className="review-btn selected"
                    >
                        <i className="fas fa-arrow-left fa-2x" />
                    </button>
                    <button
                        onClick={() => this.onGoTo("next")}
                        className="review-btn selected"
                    >
                        <i className="fas fa-arrow-right fa-2x" />
                    </button>
                </div>
            </div>
        );
    }
}

export default Reviews;
