import React from "react";
import HelpSupport from "../help/HelpSupport";
import Questions from "../help/Questions";
import "../../styles/help.css";
import questions from "../../data/questions.json";
import { DocasContent } from "../context/context";

class HelpPage extends React.Component {
    static contextType = DocasContent;

    render() {
        const content = this.context.help;
        const q = this.context.questions;

        return (
            <React.Fragment>
                <HelpSupport content={content} />
                <Questions questions={questions} content={content} q={q} />
            </React.Fragment>
        );
    }
}

export default HelpPage;
